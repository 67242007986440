import { Type } from '@angular/core';

export const COMPONENT_MAPPING: { [key: string]: () => Promise<Type<any>> | Type<any> } = {

    'widget-center12': () => import('../../core/layout/component/widget-center12/widget-center12.component').then(m => m.WidgetCenter12Component),
    'widget-center6x6': () => import('../../core/layout/component/widget-center6x6/widget-center6x6.component').then(m => m.WidgetCenter6x6Component),
    'widget-center4x8': () => import('../../core/layout/component/widget-center4x8/widget-center4x8.component').then(m => m.WidgetCenter4x8Component),
    'widget-center8x4': () => import('../../core/layout/component/widget-center8x4/widget-center8x4.component').then(m => m.WidgetCenter8x4Component),
    'widget-center4x4x4': () => import('../../core/layout/component/widget-center4x4x4/widget-center4x4x4.component').then(m => m.WidgetCenter4x4x4Component),
    'widget-center6x3x3': () => import('../../core/layout/component/widget-center6x3x3/widget-center6x3x3.component').then(m => m.WidgetCenter6x3x3Component),

    'pageLayout0': () => import('../../core/layout/home-layout/home-layout.component').then(m => m.HomeLayoutComponent),
    'pageLayout1': () => import('../../core/layout/layout1/layout1.component').then(m => m.Layout1Component),
    'pageLayout2': () => import('../../core/layout/layout2/layout2.component').then(m => m.Layout2Component),
    'pageLayout3': () => import('../../core/layout/layout3/layout3.component').then(m => m.Layout3Component),
    'pageLayout4': () => import('../../core/layout/layout4/layout4.component').then(m => m.Layout4Component),

    'HeaderComponent': () => import('../../core/layout/component/header/header.component').then(m => m.HeaderComponent),
    'FooterComponent': () => import('../../core/layout/component/footer/footer.component').then(m => m.FooterComponent),
    'FooterEnd': () => import('../../core/layout/component/footer-end/footer-end.component').then(m => m.FooterEndComponent),

    'BannerWithNewsComponent': () => import('../widget/banner-with-news/banner-with-news.component').then(m => m.BannerWithNewsComponent),
    'BannerComponent': () => import('../widget/banner/banner.component').then(m => m.BannerComponent),
    'NotificationComponent': () => import('../widget/notification/notification.component').then(m => m.NotificationComponent),
    'NewsComponent': () => import('../widget/news/news.component').then(m => m.NewsComponent),
    'GalleryComponent': () => import('../widget/gallery/gallery.component').then(m => m.GalleryComponent),
    'MemberComponent': () => import('../widget/member/member.component').then(m => m.MemberComponent),
    'ActivityFieldsComponent': () => import('../widget/activity-fields/activity-fields.component').then(m => m.ActivityFieldsComponent),
    'InnerHtmlComponent': () => import('../widget/inner-html/inner-html.component').then(m => m.InnerHtmlComponent),
    'LinkFiveColComponent': () => import('../widget/link-five-col/link-five-col.component').then(m => m.LinkFiveColComponent),

    'BannerWithTitleComponent': () => import('../widget/banner-with-title/banner-with-title.component').then(m => m.BannerWithTitleComponent),
    'BreadcrumbComponent': () => import('../widget/breadcrumb/breadcrumb.component').then(m => m.BreadcrumbComponent),
    'DisplayNewsDetailComponent': () => import('../widget/display-news-detail/display-news-detail.component').then(m => m.DisplayNewsDetailComponent),
    'HotNewsComponent': () => import('../widget/hot-news/hot-news.component').then(m => m.HotNewsComponent),
    'NewsMostViewComponent': () => import('../widget/news-most-view/news-most-view.component').then(m => m.NewsMostViewComponent),
    'SpecialNotiComponent': () => import('../widget/special-noti/special-noti.component').then(m => m.SpecialNotiComponent),
    'NewsEventComponent': () => import('../widget/news-event/news-event.component').then(m => m.NewsEventComponent),
    'NewsWithPagingComponent': () => import('../widget/news-with-paging/news-with-paging.component').then(m => m.NewsWithPagingComponent),
    'NewsWithListStyleComponent': () => import('../widget/news-with-list-style/news-with-list-style.component').then(m => m.NewsWithListStyleComponent),
    'NewsWithTimelineComponent': () => import('../widget/news-with-timeline/news-with-timeline.component').then(m => m.NewsWithTimelineComponent),
    'ContactComponent': () => import('../widget/contact/contact.component').then(m => m.ContactComponent),
    'News1Col2DisplayTypeComponent': () => import('../widget/news1-col2-display-type/news1-col2-display-type.component').then(m => m.News1Col2DisplayTypeComponent),
    'News1ColNoImageComponent': () => import('../widget/news1-col-no-image/news1-col-no-image.component').then(m => m.News1ColNoImageComponent),
    'Link1ColImageAndTextComponent': () => import('../widget/link1-col-image-and-text/link1-col-image-and-text.component').then(m => m.Link1ColImageAndTextComponent),
    'NewsWithStyle4colComponent': () => import('../widget/news-with-style4col/news-with-style4col.component').then(m => m.NewsWithStyle4colComponent),
    'DetailNewsGalleryComponent': () => import('../widget/detail-news-gallery/detail-news-gallery.component').then(m => m.DetailNewsGalleryComponent),
    'PostedNewsComponent': () => import('../widget/posted-news/posted-news.component').then(m => m.PostedNewsComponent),
    'PostedNewsVideoComponent': () => import('../widget/posted-news-video/posted-news-video.component').then(m => m.PostedNewsVideoComponent),
    'SearchComponent': () => import('../widget/search/search.component').then(m => m.SearchComponent),
    'PostedNewsTimelineComponent': () => import('../widget/posted-news-timeline/posted-news-timeline.component').then(m => m.PostedNewsTimelineComponent),
    'NewsDetailEventComponent': () => import('../widget/news-detail-event/news-detail-event.component').then(m => m.NewsDetailEventComponent),
    'TextMarqueeComponent': () => import('../widget/text-marquee/text-marquee.component').then(m => m.TextMarqueeComponent),
    'NewsDataTabsComponent': () => import('../widget/news-data-tabs/news-data-tabs.component').then(m => m.NewsDataTabsComponent),
};