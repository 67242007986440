import { Compiler, ComponentFactoryResolver, Inject, Injectable, Injector, PLATFORM_ID, Type, ViewContainerRef } from '@angular/core';
import { COMPONENT_MAPPING } from './load-dynamic-comp';
import { isPlatformServer } from '@angular/common';
import { getTextByLanguage } from '../../core/helper/helper';
import { ShareService } from '../../core/service/share.service';

@Injectable({
    providedIn: 'root'
})
export class DynamicComponentLoaderService {

    constructor(
        private injector: Injector,
        private compiler: Compiler,
        @Inject(PLATFORM_ID) private platformId: Object,
        private componentFactoryResolver: ComponentFactoryResolver,
        public shareService: ShareService
    ) { }

    async loadComponent(container: ViewContainerRef, className: string, inputs: { [key: string]: any } | null = {}) {
        const loadComponent = COMPONENT_MAPPING[className];
        if (!loadComponent) {
            return;
        }

        const componentType: Type<any> = await loadComponent();

        if (!componentType || !componentType.hasOwnProperty('ɵcmp')) {
            throw new Error(`Invalid component type: ${className}`);
        }

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
        const componentRef = container.createComponent(componentFactory);

        // Pass input data to the component instance
        if (inputs) {
            for (const [key, value] of Object.entries(inputs)) {
                if (key === 'configText') {
                    (componentRef.instance as any)[key] = await getTextByLanguage(value, this.shareService);
                } else (componentRef.instance as any)[key] = value;
            }
        }

        return componentRef;
        // const componentType = await loadComponent();
        // const componentFactory = this.injector.get(ComponentFactoryResolver).resolveComponentFactory(componentType);
        // const componentRef = container.createComponent(componentFactory);

        // // Pass input data to the component instance
        // for (const [key, value] of Object.entries(inputs)) {
        //     (componentRef.instance as any)[key] = value;
        // }

        // return componentRef;
    }
}
